<template>
	<!-- 登录弹窗 -->
	<div class="login_card">
		<a-modal v-model="loginModal" dialogClass="loginModal" :width="770" :footer="null" :centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="login_modal">
				<div class="m_left">
					<div class="logo">
						<img :src="config.company ? config.company : require('@/assets/imgs/logo.png')" alt="" />
					</div>
					<div class="input_box" v-if="type != 'wx' || qrcode.bind">
						<a-form :form="form">
							<a-form-item has-feedback v-if="type != 'wx' || qrcode.bind">
								<div class="input pl">
									<a-input prefix="+86" placeholder="请输入手机号" v-decorator="rules.mobile" />
								</div>
							</a-form-item>
							<a-form-item has-feedback v-if="type == 'code' || type == 'register' || type == 'forgot' || qrcode.bind">
								<div class="input pl">
									<a-input placeholder="请输入验证码" v-decorator="rules.code">
										<a-icon slot="prefix" type="safety-certificate" />
										<div class="code" slot="suffix" @click="getMobileCode">{{ code.tips }}</div>
									</a-input>
								</div>
							</a-form-item>
							<a-form-item has-feedback v-if="type == 'password'">
								<div class="input pl">
									<a-input-password placeholder="请输入密码" v-decorator="rules.password">
										<a-icon slot="prefix" type="lock" />
									</a-input-password>
								</div>
							</a-form-item>
							<a-form-item has-feedback v-if="type == 'register'">
								<div class="input">
									<a-input-password placeholder="请输入6~20位数字+字母或符号组合" v-decorator="rules.registerPassword"></a-input-password>
								</div>
							</a-form-item>
							<a-form-item has-feedback v-if="type == 'register'">
								<div class="input">
									<a-input-password placeholder="请再次输入密码" v-decorator="rules.againPassword"></a-input-password>
								</div>
							</a-form-item>
							<a-form-item has-feedback v-if="type == 'forgot'">
								<div class="input">
									<a-input-password placeholder="请输入新密码" v-decorator="rules.newPassword"></a-input-password>
								</div>
							</a-form-item>
						</a-form>
					</div>
					<div class="qrcode_box" v-if="type == 'wx' && !qrcode.bind">
						<div class="qrcode">
							<a-spin :spinning="qrcode.loading">
								<a-icon slot="indicator" type="loading" style="font-size: 30px" spin />
							</a-spin>
							<img v-if="!qrcode.loading" :src="qrcode.img" alt="" />
							<div class="invalid" v-if="qrcode.invalid" @click="wxAuthQrcode('refresh')">点击刷新</div>
							<div class="success" v-if="qrcode.scan">
								<a-icon type="check-circle" theme="filled" :style="{ fontSize: '30px', color: '#23B571' }" />
							</div>
						</div>
						<div class="text-center" v-if="!qrcode.scan">
							<div>微信扫码登录/注册</div>
						</div>
						<div class="text-center" v-else>
							<div>扫码成功</div>
							<div class="mt-5" style="color: red">请在微信公众号中确认登录</div>
						</div>
					</div>
					<div class="mt-10" v-if="type == 'wx' && qrcode.bind">
						<div class="text-center">
							<div style="color: red">绑定手机号，即可同步全端信息</div>
						</div>
					</div>
					<div class="type_box flex-csb text-md mt-10" v-if="type == 'password' || type == 'code' || type == 'forgot'">
						<span class="text-primary c-pointer" v-if="type == 'code'" @click="type = 'password'">手机密码登录</span>
						<span class="text-primary c-pointer" v-if="type == 'password'" @click="type = 'code'">手机验证码登录</span>
						<span class="text-primary c-pointer" v-if="type == 'forgot'" @click="type = 'password'">返回登录</span>
						<span class="c-pointer" style="color: #626a73" v-if="type == 'password'" @click="type = 'forgot'">忘记密码？</span>
					</div>
					<div class="button_box mt-20" v-if="config.mobile_switch == 1">
						<a-button type="primary" block style="font-size: 14px" @click="submitHandle">
							<span v-if="type == 'password' || type == 'code'">登录</span>
							<span v-if="type == 'register'">注册</span>
							<span v-if="type == 'forgot'">修改密码</span>
							<span v-if="type == 'wx' && !qrcode.bind">手机号登录</span>
							<span v-if="type == 'wx' && qrcode.bind">绑定手机号</span>
						</a-button>
					</div>
					<div class="tips_box text-center mt-10 text-gray-9">
						<div class="info" v-if="type == 'password' || type == 'code'">
							<span class="text-success c-pointer" @click="type = 'wx'">微信扫码登录</span>
						</div>
						<div class="info" v-if="type == 'register'">
							<span>已有账号？</span>
							<span class="text-primary c-pointer" @click="type = 'code'">立即登录</span>
						</div>
					</div>
					<div class="info_box flex-csb text-sm mt-20">
						<div style="color: #c2c7cc">
							<span>登录即代表同意</span>
							<span class="text-primary c-pointer" @click="agreementClick(1)">《用户协议》</span>
							<span>和</span>
							<span class="text-primary c-pointer" @click="agreementClick(2)">《隐私政策》</span>
						</div>
						<span class="text-primary c-pointer" v-if="type != 'register' && config.mobile_switch == 1" @click="type = 'register'">注册新账号</span>
					</div>
				</div>
				<div class="m_right">
					<div class="title">
						{{ config.name }} <br />
						智能聊天系统
					</div>
				</div>
			</div>
		</a-modal>

		<a-modal v-if="contentText" v-model="contentModal" dialogClass="contentModal" title="协议声明" :width="1000" :cancel-button-props="{ style: { display: 'none' } }" okText="关闭" @ok="contentModal = false">
			<v-md-editor v-model="contentText" mode="preview"></v-md-editor>
		</a-modal>
	</div>
</template>

<script>
	import { getUrlParams } from "@/utils/tools"
	import { checkTelPhone, checkPassword, checkAgainPassword } from "@/utils/verify"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		props: {},
		data() {
			return {
				type: "code", // (code / password / wx / register / forgot)
				loginModal: false, // 登录弹窗
				qrcode: {
					loading: true, // 二维码加载中
					invalid: false, // 二维码失效
					img: "", // 二维码图片
					scan: false, // 扫码状态
					task: null, // 定时任务
					bind: false, // 绑定手机号
					auth: "" // 微信授权ID
				},
				form: this.$form.createForm(this, {
					mobile: "",
					code: "",
					password: "",
					registerPassword: "",
					againPassword: "",
					newPassword: ""
				}),
				rules: {
					mobile: ["mobile", { rules: [{ required: true, message: "请输入手机号" }, { validator: checkTelPhone }] }],
					code: [
						"code",
						{
							rules: [
								{ required: true, message: "请输入验证码" },
								{ min: 4, max: 6, message: "请输入4~6位验证码" }
							]
						}
					],
					password: ["password", { rules: [{ required: true, message: "请输入密码" }, { validator: checkPassword }] }],
					registerPassword: ["registerPassword", { rules: [{ required: true, message: "请输入密码" }, { validator: checkPassword }] }],
					againPassword: [
						"againPassword",
						{
							rules: [
								{ required: true, message: "请再次输入密码" },
								{
									validator: (rule, value, callback) => {
										checkAgainPassword(rule, value, callback, this.form.getFieldValue("registerPassword"))
									}
								}
							]
						}
					],
					newPassword: ["newPassword", { rules: [{ required: true, message: "请输入新密码" }, { validator: checkPassword }] }]
				},
				code: {
					tips: "获取验证码",
					seconds: 60,
					status: true,
					task: null
				},
				contentModal: false,
				contentText: ""
			}
		},
		watch: {
			// 监听登录类型
			type(newValue, oldValue) {
				if (!this.loginModal) return
				if (newValue == "wx" && !this.qrcode.bind) {
					this.wxAuthQrcode()
				} else {
					clearInterval(this.qrcode.task)
				}
			},
			// 监听登录窗口
			loginModal(newValue, oldValue) {
				if (!newValue) {
					clearInterval(this.qrcode.task)
					this.setLoginPopup(false)
				}
			},
			// 深度监听
			"$store.state.user.loginPopup": {
				deep: true,
				handler: function (newValue, oldValue) {
					this.loginModal = newValue
					if (newValue && this.type == "wx" && !this.qrcode.bind) {
						this.wxAuthQrcode()
					}
				}
			},
			// 监听登录二维码失效
			"qrcode.invalid": {
				deep: true,
				handler: function (newValue, oldValue) {
					newValue && clearInterval(this.qrcode.task)
				}
			},
			// 监听是否需要绑定手机号
			"qrcode.bind": {
				deep: true,
				handler: function (newValue, oldValue) {
					newValue && clearInterval(this.qrcode.task)
				}
			}
		},
		computed: {
			...mapGetters("app", ["config"])
		},
		created() {
			if (this.config.mobile_switch != 1) this.type = "wx"
		},
		methods: {
			...mapMutations("user", ["setToken", "setLoginPopup"]),
			...mapActions("user", ["getUserInfo"]),
			// 查看协议
			agreementClick(id) {
				if (id === 1) {
					this.contentText = this.config?.user_protocol?.content
				} else {
					this.contentText = this.config?.privacy_protocol?.content
				}
				this.contentModal = true
			},
			// 提交表单
			submitHandle() {
				let obj = {}
				switch (this.type) {
					case "password":
						this.form.validateFields(["mobile", "password"], { force: true }, (errors, values) => {
							if (!errors) {
								obj = {
									account: values.mobile,
									password: values.password
								}
								this.accountLogin(obj)
							}
						})
						break
					case "code":
						this.form.validateFields(["mobile", "code"], { force: true }, (errors, values) => {
							if (!errors) {
								obj = {
									mobile: values.mobile,
									code: values.code,
									spm: getUrlParams().share || "",
									platform: "Web"
								}
								this.codeLoginRegister(obj)
							}
						})
						break
					case "register":
						this.form.validateFields(["mobile", "code", "registerPassword", "againPassword"], { force: true }, (errors, values) => {
							if (!errors) {
								obj = {
									mobile: values.mobile,
									code: values.code,
									password: values.againPassword,
									spm: getUrlParams().share || "",
									platform: "Web"
								}
								this.telPhoneRegister(obj)
							}
						})
						break
					case "forgot":
						this.form.validateFields(["mobile", "code", "newPassword"], { force: true }, (errors, values) => {
							if (!errors) {
								obj = {
									mobile: values.mobile,
									code: values.code,
									password: values.newPassword
								}
								this.forgotPassword(obj)
							}
						})
						break
					case "wx":
						if (this.qrcode.bind) {
							this.bindAuthMobile(true)
						} else {
							this.type = "code"
						}
						break
					default:
						break
				}
			},
			// 1.账号密码登录
			accountLogin(obj) {
				this.$http("login.accountLogin", obj).then(res => {
					if (res.code === 1) {
						this.$message.success("登录成功")
						this.loginModal = false
						this.setToken(res.data.token)
						this.getUserInfo(res.data.token)
					}
				})
			},
			// 2.验证码登录/注册
			codeLoginRegister(obj) {
				this.$http("login.smsLogin", obj).then(res => {
					if (res.code === 1) {
						this.$message.success("登录成功")
						this.loginModal = false
						this.setToken(res.data.token)
						this.getUserInfo(res.data.token)
					}
				})
			},
			// 3.手机号注册
			telPhoneRegister(obj) {
				this.$http("login.smsRegister", obj).then(res => {
					if (res.code === 1) {
						this.$message.success("注册成功")
						this.loginModal = false
						this.setToken(res.data.token)
						this.getUserInfo(res.data.token)
					}
				})
			},
			// 4.找回密码
			forgotPassword(obj) {
				this.$http("login.forgotPassword", obj).then(res => {
					if (res.code === 1) {
						this.$message.success("密码修改成功")
						this.type = "password"
					}
				})
			},
			// 5.绑定授权手机号
			bindAuthMobile(bind) {
				let obj = {
					user_oauth_id: this.qrcode.auth,
					spm: getUrlParams().share || ""
				}
				const api = () => {
					this.$http("login.wxBind", obj).then(res => {
						if (res.code === 1) {
							this.$message.success("登录成功")
							this.loginModal = false
							this.setToken(res.data.token)
							this.getUserInfo(res.data.token)
						}
					})
				}
				if (!bind) return api()
				this.form.validateFields(["mobile", "code"], { force: true }, (errors, values) => {
					if (!errors) {
						obj = { ...obj, mobile: values.mobile, code: values.code }
						api()
					}
				})
			},
			// 微信授权二维码
			wxAuthQrcode(t) {
				if (t != "refresh") this.loginModal = true
				this.qrcode.loading = true
				this.qrcode.invalid = false
				this.qrcode.scan = false
				this.$http("login.wxLogin")
					.then(res => {
						if (res.code === 1) {
							this.qrcode.img = res.data.url
							this.qrcode.loading = false
							this.qrcode.task = setInterval(() => {
								this.wxAuthVerify(res.data.key)
							}, 1000)
						} else {
							this.loginModal = false
						}
					})
					.catch(err => {})
			},
			// 微信授权验证
			wxAuthVerify(key) {
				this.$http("login.wxVerify", { key })
					.then(res => {
						switch (res.code) {
							case 99:
								// 已授权, 未绑定手机号
								this.qrcode.auth = res.data.user_oauth_id
								if (this.config.mobile_switch == 1) {
									this.qrcode.bind = true
								} else {
									this.bindAuthMobile(false)
								}
								break
							case 100:
								// 登录成功
								this.loginModal = false
								this.setToken(res.data.token)
								this.getUserInfo(res.data.token)
								break
							case 101:
								// 未扫码
								break
							case 102:
								// 已扫码, 未授权
								this.qrcode.scan = true
								break
							case 103:
								// 二维码过期
								this.qrcode.invalid = true
								this.qrcode.scan = false
								break
							default:
								this.loginModal = false
								this.$message.error(res.msg)
								break
						}
					})
					.catch(err => {})
			},
			// 获取验证码
			getMobileCode() {
				const { mobile } = this.form.getFieldsValue()
				if (!mobile) {
					this.$message.error("请输入手机号")
					return
				}
				if (this.code.status) {
					this.code.status = false
					let event = ""
					if (this.type == "code") {
						event = "mobilelogin"
					} else if (this.type == "register") {
						event = "register"
					} else if (this.type == "forgot") {
						event = "resetpwd"
					} else if (this.type == "wx") {
						event = "changemobile"
					}
					this.$http("login.sendCode", { mobile: mobile, event }).then(res => {
						if (res.code === 1) {
							this.$message.success("验证码发送成功，请注意查收")
							this.code.task = setInterval(() => {
								setTimeout(() => {
									this.code.seconds--
									this.code.tips = this.code.seconds + "秒重新获取"
									if (this.code.seconds <= 0) {
										this.code.seconds = 60
										this.code.tips = "重新获取"
										this.code.status = true
										clearInterval(this.code.task)
									}
								}, 0)
							}, 1000)
						} else {
							this.code.status = true
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .contentModal {
		.ant-modal-body {
			max-height: 70vh;
			overflow-y: auto;
		}
	}

	::v-deep .loginModal {
		.ant-modal-content {
			border-radius: 10px;
			overflow: hidden;
		}
	}

	.login_modal {
		display: flex;
		background: linear-gradient(210.37deg, #1665d3 0%, #319eff 100%);
		box-shadow: 3px 6px 20px 5px rgba($color: #083573, $alpha: 0.5);

		.m_left {
			width: 45%;
			background: #fff;
			padding-top: 60px;
			padding: 40px 20px 20px 20px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			.logo {
				height: 40px;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: auto;
					height: 100%;
				}
			}

			.input_box {
				margin-top: 40px;
				::v-deep .ant-form {
					.ant-form-item {
						margin-bottom: 14px;
					}
					.ant-form-item-with-help {
						margin-bottom: -4px !important;
					}

					.ant-input {
						letter-spacing: 1px;

						&::placeholder {
							font-weight: normal;
						}
					}

					.pl {
						.ant-input {
							padding-left: 40px;
						}
					}

					.code {
						cursor: pointer;
					}
				}
			}

			.qrcode_box {
				$isize: 150px;
				padding: 20px 0;
				.qrcode {
					width: $isize;
					height: $isize;
					margin: 10px auto;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					img {
						width: 100%;
						height: 100%;
					}

					.invalid {
						width: $isize;
						height: $isize;
						line-height: $isize;
						background: rgba($color: #000000, $alpha: 0.7);
						color: #fff;
						text-align: center;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 2;
						cursor: pointer;
					}

					.success {
						width: $isize;
						height: $isize;
						line-height: $isize;
						background: rgba($color: #fff, $alpha: 0.9);
						color: #000;
						text-align: center;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
		.m_right {
			width: 55%;
			background-image: url("../../assets/imgs/login.png");
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: 0 100%;
			padding-top: 30px;

			.title {
				color: #fff;
				font-size: 30px;
				font-weight: 700;
				text-align: center;
				letter-spacing: 4px;
				text-indent: 4px;
			}
		}
	}
</style>
