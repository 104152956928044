// 会话
const chat = {
	create: {
		url: "/addons/chatgpt/group/saveGroup",
		method: "GET",
		desc: "新建会话"
	},
	delete: {
		url: "/addons/chatgpt/group/delGroup",
		method: "GET",
		desc: "删除会话"
	},
	list: {
		url: "/addons/chatgpt/group/getGroupList",
		method: "GET",
		desc: "会话列表"
	},
	history: {
		url: "/addons/chatgpt/web/history",
		method: "GET",
		desc: "聊天历史记录"
	},
	sendText: {
		url: "/addons/chatgpt/web/sendText",
		method: "fetch",
		desc: "获取数据流"
	},
	mode: {
		url: "/addons/chatgpt/web/get_mode",
		method: "GET",
		desc: "GPT模型"
	},
	collect: {
		url: "/addons/chatgpt/web/msg_vote",
		method: "GET",
		desc: "对话收藏"
	},
	collectList: {
		url: "/addons/chatgpt/web/vote_msgs",
		method: "GET",
		desc: "对话收藏列表"
	},
	clearAll: {
		url: "/addons/chatgpt/web/clear_group",
		method: "GET",
		desc: "清空所有对话"
	},
	clearAlone: {
		url: "/addons/chatgpt/web/clear_msg",
		method: "GET",
		desc: "清空指定对话"
	},
	presetClassify: {
		url: "/addons/chatgpt/chat/topic",
		method: "GET",
		desc: "预设分类"
	},
	presetList: {
		url: "/addons/chatgpt/chat/prompts",
		method: "GET",
		desc: "预设内容列表"
	},
	presetDetail: {
		url: "/addons/chatgpt/chat/prompt",
		method: "GET",
		desc: "预设内容详情"
	},
	presetRandom: {
		url: "/addons/chatgpt/chat/promptsRand",
		method: "GET",
		desc: "预设随机项"
	},
	sendKey: {
		url: "/addons/chatgpt/web/sendKey",
		method: "GET",
		desc: "获取msgkey"
	},
	stopOutput: {
		url: "/addons/chatgpt/web/stopOutput",
		method: "POST",
		desc: "停止对话"
	}
}

export default chat
