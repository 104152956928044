<template>
	<div>
		<a-layout class="layout-dashboard" id="layout-dashboard" :class="[layoutClass]">
			<DashboardSidebar :sidebarCollapsed="sidebarCollapsed" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme" @toggleSidebar="toggleSidebar"></DashboardSidebar>
			<a-layout class="layout_content" :class="{ index: key == '/ai' }">
				<DashboardHeader :sidebarCollapsed="sidebarCollapsed" :navbarFixed="navbarFixed" @toggleSettingsDrawer="toggleSettingsDrawer" @toggleSidebar="toggleSidebar"></DashboardHeader>
				<a-layout-content>
					<keep-alive>
						<router-view v-if="$route.meta.keepAlive" :key="key" />
					</keep-alive>
					<router-view v-if="!$route.meta.keepAlive" :key="key" />
				</a-layout-content>
				<DashboardFooter></DashboardFooter>
				<div class="sidebar-overlay" @click="sidebarCollapsed = true" v-show="!sidebarCollapsed"></div>
			</a-layout>
			<DashboardSettingsDrawer :showSettingsDrawer="showSettingsDrawer" :navbarFixed="navbarFixed" :sidebarTheme="sidebarTheme" @toggleSettingsDrawer="toggleSettingsDrawer" @toggleNavbarPosition="toggleNavbarPosition" @updateSidebarTheme="updateSidebarTheme" @updateSidebarColor="updateSidebarColor"></DashboardSettingsDrawer>
		</a-layout>
	</div>
</template>

<script>
	import DashboardSidebar from "../components/Sidebars/DashboardSidebar"
	import DashboardHeader from "../components/Headers/DashboardHeader"
	import DashboardFooter from "../components/Footers/DashboardFooter"
	import DashboardSettingsDrawer from "../components/Sidebars/DashboardSettingsDrawer"

	export default {
		components: {
			DashboardSidebar,
			DashboardHeader,
			DashboardFooter,
			DashboardSettingsDrawer
		},
		data() {
			return {
				sidebarCollapsed: false, // 遮罩层
				sidebarColor: "primary", // 侧边栏颜色
				sidebarTheme: "light", // 侧边栏主题 light, white, dark
				navbarFixed: false, // 头部固定状态
				showSettingsDrawer: false // 设置右侧抽屉状态
			}
		},
		methods: {
			toggleSidebar(value) {
				this.sidebarCollapsed = value
			},
			toggleSettingsDrawer(value) {
				this.showSettingsDrawer = value
			},
			toggleNavbarPosition(value) {
				this.navbarFixed = value
			},
			updateSidebarTheme(value) {
				this.sidebarTheme = value
			},
			updateSidebarColor(value) {
				this.sidebarColor = value
			}
		},
		computed: {
			layoutClass() {
				return this.$route.meta.layoutClass
			},
			key() {
				return this.$route.path
			}
		}
	}
</script>
<style lang="scss" scoped>
	.layout-dashboard {
		background: linear-gradient(80deg, #edf9fe, #d9ecf8, #c9e9f5, #c4def0, #e3edf9);
		background-size: 500% 500%;
		animation: gradientBG 5s ease infinite;

		@keyframes gradientBG {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}

		.layout_content {
			&.index {
				&::before {
					content: "";
					width: 600px;
					height: 600px;
					position: fixed;
					top: 50%;
					left: 50%;
					z-index: 0;
					transform: translate(-50%, -50%);
					background-image: url("../assets/imgs/index_bg.gif");
					background-size: 100% 100%;
				}

				.ant-layout-content {
					position: relative;
				}
			}
		}
	}
</style>
