// 思维导图
const mindmap = {
	create: {
		url: "/addons/chatgpt/mindmap/general",
		method: "POST",
		desc: "生成"
	},
	view: {
		url: "/addons/chatgpt/mindmap/view",
		method: "POST",
		desc: "查看"
	},
	delete: {
		url: "/addons/chatgpt/mindmap/del",
		method: "POST",
		desc: "删除"
	},
	record: {
		url: "/addons/chatgpt/mindmap/index",
		method: "GET",
		desc: "记录"
	}
}

export default mindmap
