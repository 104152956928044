// 会话
const professional = {
    category: {
        url: "/addons/chatgpt/midjourney/getCategory",
        method: "POST",
        desc: "获取专业绘图分类  2级分类"
    },
    CategoryDetail: {
        url: "/addons/chatgpt/midjourney/getCategoryDetail",
        method: "POST",
        desc: "获取专业绘图分类详情  3级分类"
    },
    translate: {
        url: "/addons/chatgpt/midjourney/translate",
        method: "POST",
        desc: "专业绘图  中英翻译"
    },
    PictureKeyWord: {
        url: "/addons/chatgpt/midjourney/generatePicturekeywords",
        method: "POST",
        desc: "专业绘图  生成关键字"
    },

    prompt_config: {
        url: "/addons/chatgpt/draw/prompt_config",
        method: "GET",
        desc: "专业绘图  获取绘图模版数据"
    },

    generateBled: {
        url: "/addons/chatgpt/midjourney/generateBled",
        method: "POST",
        desc: "专业绘图  混合图片"
    },
    drawTaskList: {
        url: "/addons/chatgpt/draw/user_draw_msg",
        method: "GET",
        desc: "专业绘图  任务列表"
    },
    generate: {
        url: "/addons/chatgpt/midjourney/generate",
        method: "POST",
        desc: "专业绘图  生成图片"
    },
    user_draw: {
        url: "/addons/chatgpt/midjourney/user_draw",
        method: "GET",
        desc: "专业绘图  创作管理  获取作品数据"
    },

    bateDelDraw: {
        url: "/addons/chatgpt/midjourney/bateDelDraw",
        method: "POST",
        desc: "专业绘图  创作管理  删除作品数据(批量)"
    },

    drawMsgDel: {
        url: "/addons/chatgpt/midjourney/user_draw_msg_del",
        method: "POST",
        desc: "专业绘图  任务删除"
    },

    upsample: {
        url: "/addons/chatgpt/midjourney/upsample",
        method: "POST",
        desc: "专业绘图  V1U1重新生图"
    },








}

export default professional
