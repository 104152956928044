import axios from "axios"
import store from "@/store"
import { BASE_API } from "../../env"
import { message } from "ant-design-vue"

// 错误提示
let isError = true

// 创建 axios 实例
const service = axios.create({
	baseURL: BASE_API,
	timeout: 1000 * 500
})

// 请求拦截器
service.interceptors.request.use(
	config => {
		// 在请求发送之前可以在这里做一些处理，比如添加请求头信息、添加 token 等等
		store.state.user.token && (config.headers["Token"] = store.state.user.token || "")
		store.state.app.sign && (config.headers["Sign"] = store.state.app.sign || "")
		return config
	},
	error => {
		// 请求错误处理
		Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		// 一些统一的响应处理
		const res = response.data
		if (res.code != 1 && res.code != 100) {
			// 非 1 错误消息处理，比如登录过期、权限不够等等
			// 可以根据接口返回的 code 码来做逻辑处理
			Promise.reject(`错误提示 => code: ${res.code} message: ${res.msg}`)
		}

		if (res.code === 0 && isError) {
			message.error(res.msg)
		}

		return res
	},
	error => {
		// http 状态码异常处理，比如4XX、5XX等等
		if (error.response) {
			const { status, data } = error.response
			if (status === 404) {
				// 处理资源不存在的情况
				message.error("访问资源不存在！")
			} else if (status === 500) {
				// 处理其他 http 错误情况
				message.error(data.msg || "服务器开小差，请稍后再试！")
			} else if (status === 401) {
				// 未登录
				message.error(data.msg)
				store.state.user.token && store.dispatch("user/logout")
				store.commit("user/setLoginPopup", true)
			} else {
				message.error(error.message)
			}
		} else if (error.request) {
			// 处理请求出错的情况，比如网络不通、客户端未发送请求等等
		} else {
			// 其他错误处理
		}
		console.log(error,'errorerror')
		if (error.code === "ECONNABORTED" || error.message.indexOf("timeout") != -1) {
			message.error("连接超时，请刷新页面")
		}
		return Promise.reject(error.name + ": " + error.message)
	}
)

// 请求封装
const request = {
	get(url, params, timeout, error) {
		isError = error
		return service({
			url,
			method: "get",
			params,
			timeout
		})
	},
	post(url, data, timeout, error) {
		isError = error
		return service({
			url,
			method: "post",
			data,
			timeout
		})
	},
	fetch(url, data) {
		return Promise.reject("该接口暂未调试")
	}
}

export { request, service }
